<template>
    <modal
        class="upload-image-modal"
        :adaptive="true"
        :scrollable="true"
        name="upload-image-modal"
        width="920"
        height="auto"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('upload-image-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="upload-image-modal-container">
            <component
                :is="currentComponent"
                :file="selectedImage"
                :field-name="fieldName"
                :stencil-size="stencilSize"
                :stencil-type="stencilType"
                :stencil-orientations="stencilOrientations"
                @file-added="fileAdded"
                @uploaded-image="(uploadedImage) => $emit('uploaded-image', uploadedImage)"
            />
        </div>
    </modal>
</template>

<script>
export default {
    name: "UploadImageModal",
    components: {
        DragDropFile: () => import(/* webpackChunkName: "drag-drop-file" */ "@c/organisms/drag-drop-file"),
        Cropper: () => import(/* webpackChunkName: "cropper" */ "@c/organisms/cropper")
    },
    data() {
        return {
            currentComponent: "drag-drop-file",
            selectedImage: null,
            fieldName: "file",
            stencilSize: {
                width: 300,
                height: 300
            },
            stencilType: "rectangle-stencil",
            stencilOrientations: null
        }
    },
    methods: {
        fileAdded(file) {
            this.selectedImage = file;
            this.currentComponent = "cropper";
        },
        beforeOpen(event) {
            if (event.params?.fieldName) {
                this.fieldName = event.params?.fieldName;
            }

            if (event.params?.stencilSize) {
                this.stencilSize = event.params?.stencilSize;
            }

            if (event.params?.stencilType) {
                this.stencilType = event.params?.stencilType;
            }
            if (event.params?.stencilOrientations) {
                this.stencilOrientations = event.params?.stencilOrientations;
            }
        },
        beforeClose() {
            this.currentComponent = "drag-drop-file";
            this.selectedImage = null;
            this.fieldName = "file";
            this.stencilSize = { width: 300, height: 300 };
            this.stencilType = "rectangle-stencil";
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-image-modal {
    /deep/ .vm--modal {
        background-color: #1C1C1E;
        border-radius: 3px;
        padding-top: 0;
        box-shadow: 0px 0px 0px 7px #1c1c1e;
        overflow: visible;

        .close-modal {
            position: absolute;
            right: -35px;
            top: 0px;
            cursor: pointer;
            z-index: 3;

            @media(max-width: $sm) {
                right: 5px;
                top: -30px;

                img {
                    width: 15px;
                }
            }
        }

        .upload-image-modal-container {
            border: 1px dashed #cfcfcf;
        }
    }
}
</style>
